import React, { useEffect, useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Avatar from "./Avatar";
import FoodicsLogo from "src/assets/FoodicsLogo";
import { getLogout } from "src/api";
import { loadAccounts, setAccounts } from "src/redux/actions/foodics";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import Menu from "@material-ui/core/Menu";
import { Redirect } from "react-router-dom";
import ic_profile_pic from 'src/assets/ic_profile_pic.png';

function getData() {
  return {
    logo: <FoodicsLogo />,
  };
}

function Header() {
  const data = getData();
  const currentUser = useSelector((state) => state.auth.userInfo);
  const node = useRef();

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (e) => {
    // outside click
    setIsOpen(false);
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);

    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const onClick = (event) => {
    setAnchorEl(event.currentTarget);
    setIsOpen(true);
  };

  async function handleClose() {
    await getLogout();
    setIsOpen(false);
    window.location.href = process.env.REACT_APP_AUTH_URL;
  };


  const options = [
    {
      title: 'View or Edit Profile',
      url: process.env.REACT_APP_URL_GO
    },
    {
      title: 'Organization Info',
      url: process.env.REACT_APP_URL_GO + 'settings'
    },
    {
      title: 'Permissions',
      url: process.env.REACT_APP_URL_GO + 'users'
    },
    {
      title: 'Subscription & Billing',
      url: process.env.REACT_APP_URL_GO + 'billing'
    }
  ];

  const getProfileSection = (detailedView = false) => {
    return (
      <div
        className={
          'row border-radius-m py-1 ml-3 ' + (!detailedView ? 'cursor-hand' : '')
        }
        style={{ backgroundColor: 'rgba(255, 255, 255, 0.08)' }}
      >
        <img
          src={ic_profile_pic}
          alt=""
          style={{ objectFit: "scale-down", height: 35, boxShadow: '0 0 14px #0000000d', borderRadius: '50%', opacity: 0.7 }}
        />
        <div className={'bg-app flex-shrink-0 ml-3 '}>

          <div
            className={" fs-r text-align-left "}
            dangerouslySetInnerHTML={{ __html: currentUser ? currentUser.firstName + ' '+ currentUser.lastName : '' }}
            style={{
              ...{
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                opacity: !detailedView ? 0.9 : 1,
                fontSize: 13
              }
            }}
          />

          <div
            className={" fs-r text-align-left mt-xs"}
            dangerouslySetInnerHTML={{ __html: currentUser?.email }}
            style={{
              ...{
                wordWrap: "break-word",
                whiteSpace: "pre-wrap",
                opacity: 0.5, wordBreak: 'break-all', fontSize: 13
              }
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <nav
      className="navbar navbar-light bg-white py-0 dk-shadow-sm justify-content-between"
      style={{
        height: 60,
        zIndex: 100,
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      }}
    >
      <span className="navbar-brand">
        <Link
          to="/app"
          className="text-decoration-none text-dark d-flex align-items-center"
        >
          <h2 className="svg-icon svg-baseline">{data.logo}</h2>
          <span
            className="ml-2 dk-shadow"
            style={{ fontWeight: 600, fontSize: 20 }}
          >
            Foodics Connect
          </span>
        </Link>
      </span>

      {currentUser && currentUser && (
        <>
          <span style={{ cursor: 'pointer' }} ref={node} onClick={onClick}>
            <Avatar name={currentUser ? currentUser.firstName + ' '+ currentUser.lastName : ''} />
          </span>
          <Menu
            style={{ marginTop: '40px', minWidth: '200px' }}
            keepMounted
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(isOpen)}
            PaperProps={{
              style: {
                width: 300,
                padding: 0
              },
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  top: 0,
                  right: 14,
                  width: 60,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {getProfileSection()}
            <div
              className={"row bg-line my-2"}
              style={{
                ...{ height: 1, backgroundColor: '#f2f2f2' }
              }}
            />

            {options.map((option) => (
              <MenuItem style={{ fontSize: '13px' }} onClick={
                () => {
                  window.open(option.url);
                }
              }>
                {option.title}

              </MenuItem>
            ))}
            <div
              className={"row bg-line my-2"}
              style={{
                ...{ height: 1, backgroundColor: '#f2f2f2' }
              }}
            />
            <MenuItem style={{ fontSize: '13px', color: 'red' }} onClick={handleClose}> Sign Out </MenuItem>
          </Menu>
        </>
      )}
    </nav>
  );
}

export default Header;
