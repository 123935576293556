import React, { useMemo, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FoodicsTable from "src/components/FoodicsTable";
import Alert from "src/components/Alert";
import SyncStatus from "src/components/SyncStatus";
import { useDispatch, useSelector } from "react-redux";
import { loadOrders, loadConfig, loadAccounts,loadProductConfig,loadjob, loadJob, } from "src/redux/actions/foodics";
import { migrateFoodicsData } from "src/api";

function Orders() {
  const orders = useSelector((state) => state.foodics.orders);
  const data = useMemo(() => orders.data, [orders]);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.foodics.job);
  const productConfig = useSelector((state) => state.foodics.productConfig);
  const config = useSelector((state) => state.foodics.config);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState();

  useEffect(() => {
    setLoading(false);
    if(data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  useEffect(() => {
    dispatch(loadConfig());
    dispatch(loadProductConfig());
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);
  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        accessor: "cartInvoiceId",
      },
      {
        Header: "Branch",
        accessor: "branchName",
        disableSortBy: true
      },
      {
        Header: "Total Amount",
        accessor: "totalAmount",
        Cell: cell => <span>SAR {cell.value}</span>,
        className: "text-right",

      },
      {
        Header: "Business Date",
        accessor: "businessDate",
      },
      {
        Header: "Books Invoice Code",
        accessor: "deskeraId",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return <SyncStatus status={row.original.syncStatus} reason={row.original.reason} id={row.original.cartInvoiceId}/>;
        },
        disableGlobalFilter: true,
      },
      
    ],
    []
  );

  const refreshData = ({ pageSize, pageIndex, sortBy}) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setLoading(true);
    setSortBy(sortBy);
    dispatch(loadOrders(pageSize, pageIndex, sortBy));
  }; 

  return (
    <div className="p-4">
      <h3 className="mb-3">Orders</h3>

      {(!config || !productConfig)  && <Alert type="warning">
        Your synced orders will appear here. Please complete{" "}
        <Link to="/app/foodics" className="text-alert-warning">
          <u>Setup</u>
        </Link>{" "}
        before continuing.
      </Alert>}
      
      <FoodicsTable data={data.content ? data.content : []} columns={columns} cart="foodics" migrate={migrateFoodicsData} pageCount={pageCount} refresh={refreshData} pages = "orders" loading={loading}/>
    </div>
  );
}

export default Orders;
