export const CREATE_TOAST = "CREATE_TOAST";
export const DELETE_TOAST = "DELETE_TOAST";
export const MARK_TOAST_DELETED = "MARK_TOAST_DELETED";
export const SET_CURRENT_USER = "SET_CURRENT_USER";
export const SET_ACCOUNTS = "SET_ACCOUNTS";
export const SET_ORDERS = "SET_ORDERS";
export const FAILED_ACCOUNT_LOAD = "FAILED_ACCOUNT_LOAD";
export const FAILED_ORDERS_LOAD = "FAILED_ORDERS_LOAD";
export const SET_DESKERA_INFO = "SET_DESKERA_INFO";
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_CUSTOMERS = "SET_CUSTOMERS";
export const FAILED_CUSTOMERS_LOAD = "FAILED_CUSTOMERS_LOAD";
export const SET_PRODUCTS = "SET_PRODUCTS";
export const FAILED_PRODUCTS_LOAD = "FAILED_PRODUCTS_LOAD";
export const SET_AMAZON_CONFIG = "SET_AMAZON_CONFIG";
export const FAILED_AMAZON_CONFIG_LOAD = "FAILED_AMAZON_CONFIG_LOAD";

/* foodics action types */
export const FOODICS_SET_ACCOUNTS = "FOODICS_SET_ACCOUNTS";
export const FOODICS_SET_ORDERS = "FOODICS_SET_ORDERS";
export const FOODICS_FAILED_ACCOUNT_LOAD = "FOODICS_FAILED_ACCOUNT_LOAD";
export const FOODICS_FAILED_ORDERS_LOAD = "FOODICS_FAILED_ORDERS_LOAD";
export const FOODICS_SET_PURCHASES = "FOODICS_SET_PURCHASES";
export const FOODICS_FAILED_PURCHASES_LOAD = "FOODICS_FAILED_PURCHASES_LOAD";
export const FOODICS_SET_INVENTORY = "FOODICS_SET_INVENTORY";
export const FOODICS_SET_INVENTORY_TRANSACTIONS = "FOODICS_SET_INVENTORY_TRANSACTIONS";
export const FOODICS_FAILED_INVENTORY_LOAD = "FOODICS_FAILED_INVENTORY_LOAD";
export const FOODICS_FAILED_INVENTORY_TRANSACTIONS_LOAD = "FOODICS_FAILED_INVENTORY_TRANSACTIONS_LOAD";
export const FOODICS_SET_CONFIG = "FOODICS_SET_CONFIG";
export const FOODICS_FAILED_CONFIG_LOAD = "FOODICS_FAILED_CONFIG_LOAD";
export const FOODICS_SET_JOB = "FOODICS_SET_JOB";
export const FOODICS_FAILED_JOB_LOAD = "FOODICS_FAILED_JOB_LOAD";
export const FOODICS_SET_PRODUCT_CONFIG = "FOODICS_SET_PRODUCT_CONFIG";
export const FOODICS_FAILED_PRODUCT_CONFIG_LOAD = "FOODICS_FAILED_PRODUCT_CONFIG_LOAD";
export const FOODICS_SET_PAYMENT_CONFIG = "FOODICS_SET_PAYMENT_CONFIG";
export const FOODICS_FAILED_PAYMENT_CONFIG_LOAD = "FOODICS_FAILED_PAYMENT_CONFIG_LOAD";
export const FOODICS_SET_UOM_CONFIG = "FOODICS_SET_UOM_CONFIG";
export const FOODICS_FAILED_UOM_CONFIG_LOAD = "FOODICS_FAILED_UOM_CONFIG_LOAD";
export const FOODICS_SET_PURCHASE_ORDERS = "FOODICS_SET_PURCHASE_ORDERS";
export const FOODICS_FAILED_PURCHASE_ORDERS_LOAD = "FOODICS_FAILED_PURCHASE_ORDERS_LOAD";





