import { useEffect, useState } from "react";
import DeleteLogo from "src/assets/Delete";
import PlusLogo from "src/assets/Plus";
import SyncDisableIcon from "src/assets/SyncDisable";
import { CSSTransition } from "react-transition-group";
import CloseIcon from "src/assets/Close";
import CheckIcon from "src/assets/Check";
import Select from "react-select";
import FloppyDiskIcon from "src/assets/FloppyDisk";
import {
  getFoodicsRedirectUrl,
  getDeskeraRedirectUrl,
  setDisconnectedDeskeraAccount,
  getDeskeraUserInfo,
  setDisconnectedFoodicsAccount,
  getDeskeraBooksAccounts,
  setFoodicsConfig,
  setFoodicsUOMConfig,
  getFoodicsUOM,
  setFoodicsProdConfig,
  setFoodicsProducts,
  getDeskeraBooksWarehouses,
  getFoodicsProducts,
  getFoodicsProdConfig,
  getFoodicsPaymentAccountConfig,
  setFoodicsPaymentAccountConfig,
  setAccountConfig,
  activateFoodicsSetup,
} from "src/api";
import { useDispatch, useSelector } from "react-redux";
import Alert from "src/components/Alert";
import customSelectTheme from "src/utils/selectTheme";
import { setCurrentUser, setDeskeraInfo } from "src/redux/actions/auth";
import {
  loadConfig,
  setConfig,
  loadAccounts,
  loadProductConfig,
  loadUOMConfig,
  setUOMConfig,
  setProductConfig,
  loadPaymentAccountConfig,
  setPaymentAccountConfig,
} from "src/redux/actions/foodics";
import SyncIcon from "src/assets/Sync";
import { addToast } from "src/redux/actions/toasts";

function Config() {
  const [disconnectWarning, setDisconnectWarning] = useState(false);
  const [disconnectWarningFoodics, setDisconnectWarningFoodics] =
    useState(false);
  const [syncWarning, setSyncWarning] = useState(false);
  const [syncButton, setSyncButton] = useState(true);
  const [disconnectButton, setDisconnectButton] = useState(true);
  const [disconnectButtonFoodics, setDisconnectButtonFoodics] = useState(true);
  const accounts = useSelector((state) => state.foodics.accounts);
  const dispatch = useDispatch();
  const [error, setError] = useState("");
  const deskeraInfo = useSelector((state) => state.auth.deskeraInfo);
  const [deskeraAccounts, setDeskeraAccounts] = useState([]);
  const [booksProducts, setBooksProducts] = useState([]);
  const [deskeraWarehouses, setDeskeraWarehouses] = useState([]);
  const productConfig = useSelector((state) => state.foodics.productConfig);
  const paymentConfig = useSelector((state) => state.foodics.paymentConfig);
  const config = useSelector((state) => state.foodics.config);
  const [formErrors, setFormErrors] = useState({});
  const [configOpen, setConfigOpen] = useState(false);
  const [site, setSite] = useState("");
  const [name, setName] = useState("");
  const [FoodicsErrors, setFoodicsErrors] = useState({});
  const [productMappingsConfigured, setproductMappingsConfigured] =
    useState(false);
  const [paymentAccountMappingConfigured, setpaymentAccountMappingConfigured] =
    useState(false);
  const [
    productAccountMappingsConfigured,
    setproductAccountMappingsConfigured,
  ] = useState(false);
  const [saveShopLoading, setSaveShopLoading] = useState(false);
  const [saveConfigLoading, setSaveConfigLoading] = useState(false);
  const [savePaymentAccountConfigLoading, setSavePaymentAccountConfigLoading] =
    useState(false);
  const [saveProductConfigLoading, setSaveProductConfigLoading] =
    useState(false);
  const [saveSyncLoading, setSaveSyncLoading] = useState(false);
  const [isProdMapConfigured, setIsProdMapConfigured] = useState(false);
  const [isAccMapConfigured, setIsAccMapConfigured] = useState(false);

  const [uomOptions, setUOMOptions] = useState([]);
  const uomConfig = useSelector((state) => state.foodics.uomConfig);
  const [UOMMappingsConfigured, setUOMMappingsConfigured] = useState(false);
  const [saveUOMConfigLoading, setSaveUOMConfigLoading] = useState(false);
  const [configUOMMappings, setConfigUOMMappings] = useState([]);
  const userInfo = useSelector((state) => state.auth.userInfo);
  const [inventoryAccounts, setInventoryAccounts] = useState({});
  const [inventoryAccountsMapping, setInventoryAccountsMapping] = useState({});
  const [inventoryAccountsConfigured, setInventoryAccountsConfigured] =
    useState(false);
  const [saveInventoryAccountsLoading, setSaveInventoryAccountsLoading] =
    useState(false);

  const inventoryAccountMappings = [
    {
      name: "Inventory Account",
      accessor: "inventoryAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Inventory Adjustment Account",
      accessor: "inventoryAdjustmentAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Cost of Goods Sold Account",
      accessor: "cogsAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Pending Transfer Account",
      accessor: "pendingTransferAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Credit Note Account",
      accessor: "creditNoteAccountCode",
      options: deskeraAccounts,
    },
  ];

  const configProductMappings = [
    {
      name: "Sales Item",
      accessor: "salesProductCode",
      options: booksProducts,
    },
    {
      name: "Charges Item",
      accessor: "chargesProductCode",
      options: booksProducts,
    },
    {
      name: "Rounding Item",
      accessor: "roundingProductCode",
      options: booksProducts,
    },
    {
      name: "Taxes Item",
      accessor: "taxesProductCode",
      options: booksProducts,
    },
    {
      name: "Discounts Item",
      accessor: "discountsProductCode",
      options: booksProducts,
    },
    {
      name: "Gift Cards Item",
      accessor: "giftCardsProductCode",
      options: booksProducts,
    },
    {
      name: "Purchases Item",
      accessor: "purchasesProductCode",
      options: booksProducts,
    },
  ];

  const configProductAccountMappings = [
    {
      name: "Purchase Account",
      accessor: "purchaseAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Sales Account",
      accessor: "salesAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Purchase Return Account",
      accessor: "purchaseReturnAccountCode",
      options: deskeraAccounts,
    },
    {
      name: "Sales Return Account",
      accessor: "salesReturnAccountCode",
      options: deskeraAccounts,
    },
  ];

  const [cashOptions, setCashOptions] = useState([]);
  const [bankOptions, setBankOptions] = useState([]);
  const [configPaymentAccountMappings, setConfigPaymentAccountMappings] =
    useState([]);

  function initialiseConfigPaymentAccountMappings() {
    const configPaymentAccountMapping = [];

    for (let i = 0; i < paymentConfig.data.length; i++) {
      let payment = paymentConfig.data[i];
      let Name = payment.name + " (" + payment.paymentMethodType + ")";
      let options = [];
      if (payment.paymentMethodType === "CASH") {
        options = cashOptions;
      } else if (payment.paymentMethodType === "CARD") {
        options = bankOptions;
      } else {
        options = deskeraAccounts;
      }
      let configPayment = {
        name: Name,
        paymentMethod: payment.paymentMethodType,
        selectedAccount: payment.booksAccountCode,
        id: payment.id,
        options: options,
      };
      configPaymentAccountMapping.push(configPayment);
    }
    setConfigPaymentAccountMappings(configPaymentAccountMapping);
  }

  useEffect(() => {
    handleConfigLoad1();
    handleConfigLoad2();
    handleConfigLoad3();
  }, [config, productConfig, paymentConfig]);

  function handleConfigLoad1() {
    setproductMappingsConfigured(true);
    configProductMappings.forEach((configProductMapping) => {
      if (!productConfig.data[configProductMapping.accessor]) {
        setproductMappingsConfigured(false);
        return;
      }
    });
  }

  function handleConfigLoad2() {
    setpaymentAccountMappingConfigured(true);
    configPaymentAccountMappings.forEach((configPaymentAccountMapping) => {
      if (!paymentConfig.data[configPaymentAccountMapping.paymentMethod]) {
        setpaymentAccountMappingConfigured(false);
        console.log(configPaymentAccountMapping);
        return;
      }
    });
  }

  function handleConfigLoad3() {
    setproductAccountMappingsConfigured(true);
    configProductAccountMappings.forEach((configProductAccountMapping) => {
      if (!config.data[configProductAccountMapping.accessor]) {
        setproductAccountMappingsConfigured(false);
        return;
      }
    });
  }

  function handleConfigLoad4() {
    setUOMMappingsConfigured(true);
    configUOMMappings.forEach((configUOMMapping) => {
      if (!uomConfig.data[configUOMMapping.deskeraId]) {
        setUOMMappingsConfigured(false);
        return;
      }
    });
  }

  function initialiseConfigUOMMappings() {
    const configUOMMapping = [];
    for (let i = 0; i < uomConfig.data.length; i++) {
      let uom = uomConfig.data[i];
      let configUOM = {
        name: uom.uomName,
        id: uom.id,
        deskeraId: uom.deskeraId,
        options: uomOptions,
      };
      configUOMMapping.push(configUOM);
    }
    setConfigUOMMappings(configUOMMapping);
    console.log(configUOMMapping);
  }

  useEffect(() => {
    handleConfigLoad4();
  }, [uomConfig, uomOptions]);

  useEffect(() => {
    initialiseConfigUOMMappings();
  }, [uomConfig, uomOptions]);

  async function getFoodicsUOMDropdown() {
    try {
      const uom = await getFoodicsUOM();
      const uomOptionss = [];
      uom.forEach((parameter) => {
        uomOptionss.push({ value: parameter.id, label: parameter.name });
      });
      setUOMOptions(uomOptionss);
      console.log(uomOptionss);
    } catch {
      setError("Failed to load UOM Options");
    }
  }

  function setUOM(e, i) {
    const uom = uomConfig.data;
    const conf = uom.splice(i, 1)[0];
    conf.deskeraId = e.value;
    uom.splice(i, 0, conf);
    console.log(uom);

    dispatch(setUOMConfig(uom));
  }

  useEffect(() => {
    getFoodicsUOMDropdown();
    dispatch(loadAccounts());
    dispatch(loadConfig());
    dispatch(loadUOMConfig());
  }, []);

  async function saveUOMConfig(e) {
    e.preventDefault();
    setSaveUOMConfigLoading(true);
    try {
      const configResp = await setFoodicsUOMConfig(uomConfig.data);
      dispatch(setUOMConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "UOM config updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSaveUOMConfigLoading(false);
    dispatch(loadUOMConfig());
  }

  function setPaymentAccount(e, i) {
    const payments = paymentConfig.data;
    const conf = payments.splice(i, 1)[0];

    conf.booksAccountCode = e.value;
    payments.splice(i, 0, conf);

    dispatch(setPaymentAccountConfig(payments));
  }

  async function getBooksAccounts() {
    try {
      const accounts = await getDeskeraBooksAccounts();
      const accountsOptions = [];
      const cashoptions = [];
      const bankoptions = [];

      accounts.forEach((account) => {
        if (account.accountGroup === "Cash")
          cashoptions.push({ value: account.code, label: account.name });
        accountsOptions.push({
          value: account.code,
          label: account.name,
        });
        if (account.accountGroup === "Bank")
          bankoptions.push({ value: account.code, label: account.name });
      });

      setDeskeraAccounts(accountsOptions);
      setCashOptions(cashoptions);
      setBankOptions(bankoptions);
    } catch {
      setError("Failed to load Deskera Books accounts");
    }
  }

  async function getFoodicsProductsDropdown() {
    try {
      const products = await getFoodicsProducts();
      const productsOptions = [];
      products.forEach((product) => {
        productsOptions.push({ value: product.productId, label: product.name });
      });
      setBooksProducts(productsOptions);
    } catch {
      setError("Failed to load Products");
    }
  }

  async function getBooksWarehouses() {
    try {
      const warehouses = await getDeskeraBooksWarehouses();
      const warehouseOptions = [];
      warehouses.forEach((warehouse) => {
        warehouseOptions.push({ value: warehouse.code, label: warehouse.name });
      });
      setDeskeraWarehouses(warehouseOptions);
    } catch {
      setError("Failed to load Deskera Books warehouses");
    }
  }

  useEffect(() => {
    getFoodicsProductsDropdown();
    dispatch(loadAccounts());
    dispatch(loadConfig());
    getBooksAccounts();
    dispatch(loadPaymentAccountConfig());
    dispatch(loadProductConfig());
  }, []);

  useEffect(() => {
    initialiseConfigPaymentAccountMappings();
  }, [paymentConfig, cashOptions, bankOptions, deskeraAccounts]);

  async function handleAddDeskeraAccount(e) {
    e.preventDefault();
    try {
      const deskeraConnectUrl = await getDeskeraRedirectUrl();
      window.open(deskeraConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch deskera url");
    }
  }

  async function handleDisconnectDeskeraAccount(e) {
    e.preventDefault();
    try {
      await setDisconnectedDeskeraAccount();
      const userInfo = await getDeskeraUserInfo();
      dispatch(setDeskeraInfo(userInfo));
    } catch {
      setError("Failed to disconnect Deskera account");
    }
  }

  async function handleDisconnectFoodicsAccount(e, accountId) {
    e.preventDefault();
    try {
      await setDisconnectedFoodicsAccount(accountId);
      dispatch(loadAccounts());
    } catch {
      setError("Failed to disconnect Foodics account");
    }
  }

  async function saveAccountConfigs(e) {
    e.preventDefault();
    setSaveConfigLoading(true);
    var request = { ...config.data, accountId: accounts.data[0].id };
    // delete request['id'];

    if (!request.syncEnabled) {
      request = { ...request, syncEnabled: false };
    }

    try {
      const configResp = await setFoodicsConfig(request);
      dispatch(setConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Accounts config updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSaveConfigLoading(false);
    dispatch(loadConfig());
  }

  async function saveProductsConfig(e) {
    e.preventDefault();
    setSaveProductConfigLoading(true);
    try {
      const configResp = await setFoodicsProdConfig({
        ...productConfig.data,
        accountId: accounts.data[0].id,
      });

      dispatch(setProductConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Products config updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSaveProductConfigLoading(false);
    dispatch(loadProductConfig());
  }

  async function savePaymentAccountConfig(e) {
    e.preventDefault();
    setSavePaymentAccountConfigLoading(true);
    try {
      const configResp = await setFoodicsPaymentAccountConfig(
        paymentConfig.data
      );
      dispatch(setPaymentAccountConfig(configResp));
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Payment Account updated successfully.",
        })
      );
    } catch {
      setError("Failed to set config");
    }
    setSavePaymentAccountConfigLoading(false);
    dispatch(loadPaymentAccountConfig());
  }

  async function handleUpdateSync(e, syncStatus) {
    e.preventDefault();
    const newConfig = { ...config.data, syncEnabled: syncStatus };
    if (
      newConfig &&
      !newConfig.accountId &&
      accounts.data &&
      accounts.data.length > 0
    ) {
      newConfig["accountId"] = accounts.data[0].id;
    }
    setSaveSyncLoading(true);
    try {
      const configResp = await setFoodicsConfig(newConfig);
      dispatch(setConfig(configResp));
      setSyncButton(true);
      setSyncWarning(false);
    } catch {
      setError("Failed to set config");
    }

    try {
      const setupResp = await activateFoodicsSetup();
    } catch (err) {
      setError("Failed to migrate setup");
    }

    setSaveSyncLoading(false);
  }

  function connectModalFoodics() {
    return (
      <div>
        {accounts.data.length === 0 && deskeraInfo.accountConnected && (
          <div>
            <div className="border-bottom w-600 mb-2 text-muted">
              <h5>Foodics Stores</h5>
            </div>
            <div className="card w-600 mb-4 dk-card dk-card-shadow">
              <div className="card-body">
                <div>
                  <div className="d-flex mb-2">
                    <p className="text-muted">Link your Foodics accounts.</p>
                  </div>
                  <div className="d-flex flex-row-reverse mb-2">
                    <button
                      className="btn dk-btn font-weight-bold text-success px-3 border border-success"
                      onClick={(e) => handleAddFoodicsAccount(e)}
                    >
                      <span className="svg-icon svg-baseline mr-2 svg-success">
                        <PlusLogo />
                      </span>
                      <span>Add Store</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  function connectedModalFoodics() {
    return (
      <>
        <div className="border-bottom w-600 mb-2 text-muted">
          <h5>Foodics Stores</h5>
        </div>
        <div className="card w-600 mb-4 dk-card dk-card-shadow">
          <div className="card-body">
            {accounts.data.length > 0 && (
              <p className="text-muted">Your Foodics account is linked.</p>
            )}

            <table className="table m-0 table-no-padding">
              {disconnectButtonFoodics && (
                <thead>
                  <tr>
                    <th>Account</th>
                    <th></th>
                  </tr>
                </thead>
              )}
              <tbody>
                {accounts.data.map((account, index) => (
                  <div style={{ width: "100%" }}>
                    {disconnectButtonFoodics && (
                      <tr style={{ width: "100%" }}>
                        <td className="align-middle">
                          <p className="m-0">
                            <b>{account.name}</b>
                          </p>
                          <span
                            class="d-inline-block text-truncate"
                            style={{ width: "300px" }}
                          >
                            <small>{account.id}</small>
                          </span>
                        </td>
                        <td
                          className="align-middle text-right"
                          style={{ width: 260 }}
                        >
                          <button
                            className="btn text-danger px-4 dk-btn border border-danger"
                            onClick={(e) => {
                              setDisconnectWarningFoodics(true);
                            }}
                          >
                            <span className="svg-icon svg-baseline mr-2 svg-danger">
                              <DeleteLogo />
                            </span>
                            <span>Disconnect</span>
                          </button>
                        </td>
                      </tr>
                    )}
                    {
                      <CSSTransition
                        in={disconnectWarningFoodics}
                        timeout={100}
                        classNames="scale-opacity"
                        unmountOnExit
                        onEnter={() => setDisconnectButtonFoodics(false)}
                        onExited={() => setDisconnectButtonFoodics(true)}
                      >
                        <Alert className="m-0 flex-fill" type="warning">
                          <p className="m-0">
                            Disconnecting will delete data. Continue?
                          </p>
                          <div className="d-flex flex-row-reverse">
                            <button
                              className="btn dk-btn font-weight-bold text-muted px-3"
                              onClick={(e) => {
                                handleDisconnectFoodicsAccount(e, account.id);
                              }}
                            >
                              <span className="svg-icon svg-baseline svg-warning">
                                <CheckIcon />
                              </span>
                            </button>
                            <button
                              className="btn dk-btn mr-2 px-3"
                              onClick={(e) =>
                                setDisconnectWarningFoodics(false)
                              }
                            >
                              <span className="svg-icon svg-baseline svg-warning">
                                <CloseIcon />
                              </span>
                            </button>
                          </div>
                        </Alert>
                      </CSSTransition>
                    }
                  </div>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </>
    );
  } // <div className="card-body">
  //   <p className="text-muted">Your Deskera Account is Connected</p>
  //   <div className="d-flex flex-row-reverse">
  // {disconnectButton && (
  // <button
  // className="btn text-danger px-4 dk-btn font-weight-bold border border-danger"
  //         onClick={(e) => setDisconnectWarning(true)}
  //       >
  //         <span className="svg-icon svg-baseline mr-2 svg-danger">
  //           <DeleteLogo />
  //         </span>
  //         <span>Disconnect</span>
  //       </button>
  //     )}
  // <CSSTransition
  //   in={disconnectWarning}
  //   timeout={100}
  //   classNames="scale-opacity"
  //   unmountOnExit
  //   onEnter={() => setDisconnectButton(false)}
  //   onExited={() => setDisconnectButton(true)}
  // >
  //   <Alert className="m-0 flex-fill" type="warning">
  //     <p className="m-0">Disconnecting will delete data. Continue?</p>
  //     <div className="d-flex flex-row-reverse">
  //       <button
  //         className="btn dk-btn font-weight-bold text-muted px-3"
  //         onClick={handleDisconnectDeskeraAccount}
  //       >
  //         <span className="svg-icon svg-baseline svg-warning">
  //           <CheckIcon />
  //         </span>
  //       </button>
  //       <button
  //         className="btn dk-btn mr-2 px-3"
  //         onClick={(e) => setDisconnectWarning(false)}
  //       >
  //         <span className="svg-icon svg-baseline svg-warning">
  //           <CloseIcon />
  //         </span>
  //       </button>
  //     </div>
  //   </Alert>
  // </CSSTransition>
  //   </div>
  // </div>

  function connectModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Connect your Deskera account.</p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn text-success px-4 dk-btn font-weight-bold border border-success"
            onClick={handleAddDeskeraAccount}
          >
            <span className="svg-icon svg-baseline mr-2 svg-success">
              <PlusLogo />
            </span>
            <span>Connect</span>
          </button>
        </div>
      </div>
    );
  }

  function connectedModal() {
    return (
      <div className="card-body">
        <p className="text-muted">Your Deskera Account is Connected</p>
        <div className="d-flex justify-content-between align-items-center">
          <div className="font-weight-bold">{userInfo ? userInfo.tenantName : ''}</div>
        <div className="d-flex flex-row-reverse">
          {disconnectButton && (
            <button
              className="btn text-danger px-4 dk-btn font-weight-bold border border-danger"
              onClick={(e) => setDisconnectWarning(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-danger">
                <DeleteLogo />
              </span>
              <span>Disconnect</span>
            </button>
          )}
          <CSSTransition
            in={disconnectWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setDisconnectButton(false)}
            onExited={() => setDisconnectButton(true)}
          >
            <Alert className="m-0 flex-fill" type="warning">
              <p className="m-0">Disconnecting will delete data. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={handleDisconnectDeskeraAccount}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setDisconnectWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
        </div>
      </div>
    );
  }

  function FoodicsConnect() {
    return (
      <div
        className="vw-100 vh-100 d-flex justify-content-center align-items-center"
        style={{
          zIndex: 99,
          position: "absolute",
          top: 0,
          left: 0,
          background: "rgba(0, 0, 0, 0.4)",
        }}
      >
        <div className="card w-600 dk-card dk-card-shadow mb-4">
          <div className="card-body">
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Store Name</b>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Ex. Nunes Greens"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
            <div className="form-group">
              <div className="text-muted mb-2" aria="label">
                <b>Store URL</b>
              </div>
              <input
                id="site"
                type="text"
                className="form-control"
                placeholder="Ex. https://nunes-greens.com"
                value={site}
                onChange={(e) => setSite(e.target.value)}
              />
              {FoodicsErrors.site && (
                <span className="text-danger">{FoodicsErrors.site}</span>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
  async function handleAddFoodicsAccount(e) {
    e.preventDefault();
    try {
      const foodicsConnectUrl = await getFoodicsRedirectUrl();
      window.open(foodicsConnectUrl.url, "_self");
    } catch {
      setError("Failed to fetch redirect url");
    }
  }
  function syncEnabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Sync is enabled for your account. Orders, Inventories and Purchases
          from your Foodics store will be synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          {syncButton && (
            <button
              className="btn text-danger px-4 dk-btn font-weight-bold border border-danger"
              onClick={(e) => setSyncWarning(true)}
            >
              <span className="svg-icon svg-baseline mr-2 svg-danger">
                <SyncDisableIcon />
              </span>
              <span>Deactivate</span>
            </button>
          )}
          <CSSTransition
            in={syncWarning}
            timeout={100}
            classNames="scale-opacity"
            unmountOnExit
            onEnter={() => setSyncButton(false)}
            onExited={() => setSyncButton(true)}
          >
            <Alert type="warning" className="m-0 flex-fill">
              <p className="m-0">Data will no longer be synced. Continue?</p>
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-muted px-3"
                  onClick={(e) => handleUpdateSync(e, false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CheckIcon />
                  </span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3"
                  onClick={(e) => setSyncWarning(false)}
                >
                  <span className="svg-icon svg-baseline svg-warning">
                    <CloseIcon />
                  </span>
                </button>
              </div>
            </Alert>
          </CSSTransition>
        </div>
      </div>
    );
  }

  function syncDisabledModal() {
    return (
      <div className="card-body">
        <p className="text-muted">
          Your account is not activated. Orders, Inventories and Purchases from
          your Foodics store are not being synced with Deskera Books.
        </p>
        <div className="d-flex flex-row-reverse">
          <button
            className="btn text-success px-4 dk-btn font-weight-bold border border-success"
            onClick={(e) => handleUpdateSync(e, true)}
            disabled={saveSyncLoading}
          >
            <span className="svg-icon svg-baseline mr-2 svg-success">
              {saveSyncLoading ? (
                <div
                  class="spinner-border text-success spinner-border-sm"
                  role="status"
                ></div>
              ) : (
                <SyncIcon />
              )}
            </span>
            <span>Activate</span>
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4 w-auto">
      {configOpen && FoodicsConnect()}
      <h3 className="mb-4">Account Setup</h3>
      <div className="border-bottom w-600 mb-2 text-muted">
        <h5>Deskera Account</h5>
      </div>
      <div className="card w-600 dk-card dk-card-shadow mb-4">
        {deskeraInfo.accountConnected ? connectedModal() : connectModal()}
      </div>
      <div>
        {(deskeraInfo.accountConnected && accounts.data.length)
          ? connectedModalFoodics()
          : connectModalFoodics()}
      </div>
      {/* <>
        <div className="border-bottom w-600 mb-2 text-muted">
          <h5>Foodics Stores</h5>
        </div>
        <div className="card w-600 mb-4 dk-card dk-card-shadow">
          <div className="card-body">
            {accounts.data.length > 0 && (
              <p className="text-muted">Your Foodics account is linked.</p>
            )}
            {accounts.data.length === 0 && (
              <div className="d-flex flex-row-reverse mb-2">
                <p className="text-muted">Link your Foodics accounts.</p>
                <button
                  className="btn dk-btn font-weight-bold text-success px-3 border border-success"
                  onClick={(e) => handleAddFoodicsAccount(e)}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-success">
                    <PlusLogo />
                  </span>
                  <span>Add Store</span>
                </button>
              </div>
            )}
            {accounts.data.length > 0 && (
              <table className="table m-0 table-no-padding">
                <thead>
                  <tr>
                    <th>Account</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {accounts.data.map((account, index) => (
                    <tr>
                      <td className="align-middle">
                        <p className="m-0">
                          <b>{account.name}</b>
                        </p>
                        <span
                          class="d-inline-block text-truncate"
                          style={{ width: "300px" }}
                        >
                          <small>{account.id}</small>
                        </span>
                      </td>
                      <td
                        className="align-middle text-right"
                        style={{ width: 180 }}
                      >
                        <button
                          className="btn text-danger px-4 dk-btn border border-danger"
                          onClick={(e) => {
                            handleDisconnectFoodicsAccount(e, account.id);
                          }}
                        >
                          <span className="svg-icon svg-baseline mr-2 svg-danger">
                            <DeleteLogo />
                          </span>
                          <span>Disconnect</span>
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        </div>
      </> */}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Product Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!productMappingsConfigured && (
                <Alert type="primary">
                  Default products should be configured before Foodics Sync can
                  be activated.
                </Alert>
              )}
              {configProductMappings.map((configProductMapping) => (
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>{configProductMapping.name}</b>
                  </div>
                  <Select
                    placeholder="Select a Product"
                    theme={customSelectTheme}
                    options={configProductMapping.options}
                    isSearchable={true}
                    menuPlacement="auto"
                    onChange={(e) => {
                      dispatch(
                        setProductConfig({
                          ...productConfig.data,
                          [configProductMapping.accessor]: e.value,
                        })
                      );
                    }}
                    value={configProductMapping.options.filter(
                      (obj) =>
                        obj.value ===
                        productConfig.data[configProductMapping.accessor]
                    )}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {formErrors[configProductMapping.accessor] && (
                    <div>{formErrors[configProductMapping.accessor]}</div>
                  )}
                </div>
              ))}
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-success border border-success px-4"
                  onClick={saveProductsConfig}
                  disabled={saveProductConfigLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-success">
                    {saveProductConfigLoading ? (
                      <div
                        class="spinner-border text-success spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {/* {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Product Account Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!productAccountMappingsConfigured && (
                <Alert type="primary">
                  Accounts must be configured before Foodics products can be
                  synced with Deskera Books.
                </Alert>
              )}
              {configProductAccountMappings.map(
                (configProductAccountMapping) => (
                  <div className="form-group">
                    <div className="text-muted mb-2" aria="label">
                      <b>{configProductAccountMapping.name}</b>
                    </div>
                    <Select
                      placeholder="Select an Account"
                      theme={customSelectTheme}
                      options={configProductAccountMapping.options}
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={(e) =>
                        dispatch(
                          setConfig({
                            ...config.data,
                            [configProductAccountMapping.accessor]: e.value,
                          })
                        )
                      }
                      value={configProductAccountMapping.options.filter(
                        (obj) =>
                          obj.value ===
                          config.data[configProductAccountMapping.accessor]
                      )}
                      menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                    />
                    {formErrors[configProductAccountMapping.accessor] && (
                      <div>
                        {formErrors[configProductAccountMapping.accessor]}
                      </div>
                    )}
                  </div>
                )
              )}
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-success border border-success px-4"
                  onClick={saveAccountConfigs}
                  disabled={saveConfigLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-success">
                    {saveConfigLoading ? (
                      <div
                        class="spinner-border text-success spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )} */}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Activate</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            {config.data.syncEnabled ? syncEnabledModal() : syncDisabledModal()}
          </div>
        </>
      )}
      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Payment Account Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!paymentAccountMappingConfigured && !paymentConfig && (
                <Alert type="primary">
                  Accounts must be configured before Payment Accounts can be
                  synced with Deskera Books.
                </Alert>
              )}
              {configPaymentAccountMappings.length === 0 && (
                <span className="mt-2 text-muted">
                  No payment method accounts found.
                </span>
              )}
              {configPaymentAccountMappings.length > 0 &&
                configPaymentAccountMappings.map(
                  (configPaymentAccountMapping, idx) => (
                    <div className="form-group">
                      <div className="text-muted mb-2" aria="label">
                        <b>{configPaymentAccountMapping.name}</b>
                      </div>
                      <Select
                        placeholder="Select an Account"
                        theme={customSelectTheme}
                        options={configPaymentAccountMapping.options}
                        isSearchable={true}
                        menuPlacement="auto"
                        onChange={(e) => {
                          setPaymentAccount(e, idx);
                        }}
                        value={configPaymentAccountMapping.options.filter(
                          (obj) =>
                            obj.value ===
                            configPaymentAccountMapping.selectedAccount
                        )}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                        }}
                      />
                      {formErrors[
                        configPaymentAccountMapping.selectedAccount
                      ] && (
                        <div>
                          {
                            formErrors[
                              configPaymentAccountMapping.selectedAccount
                            ]
                          }
                        </div>
                      )}
                    </div>
                  )
                )}
              {configPaymentAccountMappings.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={savePaymentAccountConfig}
                    disabled={savePaymentAccountConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {savePaymentAccountConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )}
      {/* {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>UOM Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!UOMMappingsConfigured && !uomConfig && (
                <Alert type="primary">
                  Accounts must be configured before Payment Accounts can be
                  synced with Deskera Books.
                </Alert>
              )}
              {uomConfig.data.length === 0 && (
                <span className="mt-2 text-muted">No UOM Mappings found.</span>
              )}
              {uomConfig.data.length > 0 &&
                configUOMMappings.map((configUOMMapping, i) => (
                  <div className="form-group">
                    <div className="text-muted mb-2" aria="label">
                      <b>{configUOMMapping.name}</b>
                    </div>
                    <Select
                      placeholder="Select an Account"
                      theme={customSelectTheme}
                      options={configUOMMapping.options}
                      isSearchable={true}
                      menuPlacement="auto"
                      onChange={(e) => {
                        setUOM(e, i);
                      }}
                      value={configUOMMapping.options.filter(
                        (obj) => obj.value == configUOMMapping.deskeraId
                      )}
                    />
                    {formErrors[configUOMMapping.deskeraId] && (
                      <div>{formErrors[configUOMMapping.deskeraId]}</div>
                    )}
                  </div>
                ))}
              {uomConfig.data.length > 0 && (
                <div className="d-flex flex-row-reverse">
                  <button
                    className="btn dk-btn font-weight-bold text-success border border-success px-4"
                    onClick={saveUOMConfig}
                    disabled={saveUOMConfigLoading}
                  >
                    <span className="svg-icon svg-baseline mr-2 svg-success">
                      {saveUOMConfigLoading ? (
                        <div
                          class="spinner-border text-success spinner-border-sm"
                          role="status"
                        ></div>
                      ) : (
                        <FloppyDiskIcon />
                      )}
                    </span>
                    <span>Save</span>
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
      )} */}

      {deskeraInfo.accountConnected && accounts.data.length > 0 && (
        <>
          <div className="border-bottom w-600 mb-2 text-muted">
            <h5>Inventory Account Mappings</h5>
          </div>
          <div className="card w-600 mb-4 dk-card dk-card-shadow">
            <div className="card-body">
              {!setInventoryAccountsConfigured && (
                <Alert type="primary">
                  Accounts must be configured before Foodics inventory
                  transactions can be synced with Deskera Books.
                </Alert>
              )}
              {inventoryAccountMappings.map((iaMapping) => (
                <div className="form-group">
                  <div className="text-muted mb-2" aria="label">
                    <b>{iaMapping.name}</b>
                  </div>
                  <Select
                    placeholder="Select an Account"
                    theme={customSelectTheme}
                    options={iaMapping.options}
                    isSearchable={true}
                    menuPlacement="auto"
                    onChange={(e) =>
                      dispatch(
                        setConfig({
                          ...config.data,
                          [iaMapping.accessor]: e.value,
                        })
                      )
                    }
                    value={iaMapping.options.filter(
                      (obj) => obj.value === config.data[iaMapping.accessor]
                    )}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                    }}
                  />
                  {formErrors[iaMapping.accessor] && (
                    <div>{formErrors[iaMapping.accessor]}</div>
                  )}
                </div>
              ))}
              <div className="d-flex flex-row-reverse">
                <button
                  className="btn dk-btn font-weight-bold text-success border border-success px-4"
                  onClick={saveAccountConfigs}
                  disabled={saveConfigLoading}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-success">
                    {saveConfigLoading ? (
                      <div
                        class="spinner-border text-success spinner-border-sm"
                        role="status"
                      ></div>
                    ) : (
                      <FloppyDiskIcon />
                    )}
                  </span>
                  <span>Save</span>
                </button>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}

export default Config;
