import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { loadConfig, loadJob, loadInventoryTransactions } from "src/redux/actions/foodics";
import Alert from "src/components/Alert";
import Table from "src/components/Table";
import SyncStatus from "src/components/SyncStatus";
import getSymbolFromCurrency from "currency-symbol-map";
import { migrateFoodicsData } from "src/api";
import { addToast } from "src/redux/actions/toasts";
import FoodicsTable from "src/components/FoodicsTable";
import CloseIcon from "src/assets/Close";
import {
  updateFoodicsInventoryTransactions,
} from "src/api";



function InventoryTransactions() {
  const [syncStatus, setSyncStatus] = useState("enabled");
  const inventoryTransactions = useSelector((state) => state.foodics.inventoryTransactions);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.foodics.job);
  const [updateRow, setUpdateRow] = useState();
  const [updatedAmount, setUpdatedAmount] = useState(0);
  const [triggered, setTriggered] = useState(false);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const data = useMemo(() => inventoryTransactions.data, [inventoryTransactions]);
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState();

  useEffect(() => {
    dispatch(loadConfig());
  }, []);

  useEffect(() => {
    dispatch(loadJob());
  }, job);

  useEffect(() => {
    setLoading(false);
    if(data && data.totalPages) {
      setPageCount(data.totalPages);
    }
  }, [data]);

  const handleShow = (cell) => {
    setUpdateRow(cell?.row?.original)
}

  const columns = useMemo(
    () => [
      {
        Header: "Transaction ID",
        accessor: "reference",
      },
      {
        Header: "Branch",
        accessor: "branchName",
        disableSortBy: true
      },
      {
        Header: "Transaction Type",
        accessor: "transactionType",
        disableSortBy: true
      },
      {
        Header: "Business Date",
        accessor: "businessDate",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartProductId}
            />
          );
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Deskera Journal Code",
        accessor: "defaultDeskeraId",
      },
      {
        Header: 'Action',
        accessor: 'totalAmount',
        className: "update-amount-col",
        disableGlobalFilter: true,
        disableSortBy: true,
        Cell: props => props?.row?.original?.syncStatus === "PENDING" && props?.row?.original?.totalAmount === 0 && (<button className="btn btn-outline-success" style={{ lineHeight: 1 }} onClick={() => handleShow(props)}>Update Amount</button>),
      }
    ],
    []
  );
  
  const config = useSelector((state) => state.foodics.config);

  async function handleSyncDataSubmit() {
    try {
      const resp = await migrateFoodicsData();
    } catch (err) {
      dispatch(addToast({
        type: "danger",
        title: "Failure",
        message: err.message
      }))
    }
  }

  const refreshData = ({ pageSize, pageIndex, sortBy}) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setLoading(true);
    setSortBy(sortBy);
    dispatch(loadInventoryTransactions(pageSize, pageIndex, sortBy));
  }; 

  async function updateInventoryTransactions() {
    if (!updateRow || !updateRow.foodicsInventoryTransactionId || updatedAmount === 0) {
      return
    }
    setTriggered(true);
    const requestBody = {
      totalAmount: updatedAmount
    };
    try {
      await updateFoodicsInventoryTransactions(updateRow.foodicsInventoryTransactionId, requestBody);
      dispatch(
        addToast({
          type: "success",
          title: "Success",
          message: "Sync job completed",
        })
      );
      setTriggered(false)
      setUpdateRow();
      setUpdatedAmount(0);
      dispatch(loadInventoryTransactions(pageSize, pageIndex, sortBy));
    } catch (err) {
      setTriggered(false)
    }
  }

  function inventoryTransactionsAmoutUpdate() {
    return (
      <div className="d-flex">
        <div
          className="vw-100 vh-100 d-flex justify-content-center align-items-center"
          style={{
            zIndex: 99,
            position: "absolute",
            top: 0,
            left: 0,
            background: "rgba(0, 0, 0, 0.4)",
          }}
        >
          <div
            className="card dk-card dk-card-shadow d-flex"
            style={{ minWidth: "30%" }}
          >
            <div className="card-body d-flex flex-column" style={{ maxWidth: 550 }}>
              <div className="card-body d-flex flex-column">
                {/* <div className="text-muted mb-2">Update Inventory Transaction Amount</div> */}
                <div className="form-group">
                  <div className="text-muted mb-3" aria="label">
                    <b>Enter amount for inventory transaction of {updateRow?.transactionType}</b>
                  </div>
                  <input
                    type="number"
                    className="form-control"
                    value={updatedAmount}
                    onChange={(e) => setUpdatedAmount(e.target.value)}
                  />
                </div>

                <div>
                  <span
                    data-tip="React-tooltip"
                    data-for={updateRow}
                    className="text-muted"
                  >
                    *The cost of this transaction is zero and system can not create {updateRow?.transactionType ? updateRow?.transactionType : 'document'} with zero cost. Please update the cost.
                  </span>
                </div>
              </div>
              <div className="d-flex flex-row-reverse mt-3">
                <button
                  className="btn dk-btn font-weight-bold text-success border border-success px-4"
                  onClick={(e) => {
                    updateInventoryTransactions();
                  }}
                  disabled={ updatedAmount && Number(updatedAmount) > 0 && !triggered ? false : true}
                >
                  <span>Update and Sync</span>
                </button>
                <button
                  className="btn dk-btn mr-2 px-3 font-weight-bold text-muted border border-secondary"
                  onClick={(e) => {
                    setUpdateRow(null);
                    setUpdatedAmount(0);
                  }}
                  disabled={triggered}
                >
                  <span className="svg-icon svg-baseline mr-2 svg-disabled">
                    <CloseIcon />
                  </span>
                  <span>Cancel</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="p-4">
      <h3 className="mb-3">Inventory Transactions</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced inventory transactions will appear here. Please complete{" "}
          <Link to="/app/Foodics" className="text-alert-warning">
            <u>Setup</u>
          </Link>{" "}
          before continuing.
        </Alert>
      )}
            {updateRow && inventoryTransactionsAmoutUpdate()}
      <FoodicsTable data={data.content ? data.content : []} columns={columns} migrate={migrateFoodicsData} pageCount={pageCount} refresh={refreshData} cart='foodics' pages = "inventoryTransactions" loading={loading}/>
    </div>
  );
}

export default InventoryTransactions;
