import axios from "axios";
import querystring from "querystring";

const API = axios.create({
  withCredentials: true,
  headers: {
    "Access-Control-Allow-Credentials": "true",
    "Access-Control-Allow-Origin": "*",
  },
});

API.interceptors.response.use(
  function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    // check whether request was a network failure, or unauthenticated or failed to login status
    if (
      !originalRequest._retry &&
      (!error.response ||
        error.response.status === 401 ||
        originalRequest.url.includes("/login/status"))
    ) {
      // if its refreshtoken request that failed, do not retry
      if (originalRequest.url.includes("getrefreshtoken")) {
        return Promise.reject(error);
      }

      originalRequest._retry = true;
      try {
        await refreshAccessToken();
      } catch {
        return Promise.reject(error);
      }

      return API(originalRequest);
    }
    throw error;
  }
);

export function setAuthToken(token) {
  API.defaults.headers["x-access-token"] = token;
}

export async function getLoginStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_LOGIN_STATUS_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getLogout() {
  try {
    const resp = await API.get(process.env.REACT_APP_BASE_URL + 'v1/iam/auth/logout');
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function refreshAccessToken() {
  const requestBody = {
    refreshToken: localStorage.getItem("deskera-refresh-token"),
  };
  try {
    const resp = await API.post(process.env.REACT_APP_TOKEN_REFRESH_URL, requestBody);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setAccountConfig(config) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/deskera/accounts", config);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraRedirectUrl() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/redirectUrl?app=foodics");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedDeskeraAccount() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/deskera/disconnect");
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getDeskeraAccount() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/user");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraBooksAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/books/accounts");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraBooksWarehouses() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/books/warehouses");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getTenantInfo() {
  try {
    const resp = await API.get(process.env.REACT_APP_TENANT_URL);
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function setDeskeraConnection(connectionInfo) {
  let amazonConnectionUrl = new URL(process.env.REACT_APP_SERVER_URL + "/deskera/oauth");

  amazonConnectionUrl.searchParams.append("code", connectionInfo.code);
  amazonConnectionUrl.searchParams.append("state", connectionInfo.state);
  amazonConnectionUrl.searchParams.append("app", 'foodics');

  try {
    const resp = await API.get(amazonConnectionUrl.toString());
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraLoginUserInfo(userId) {
  try {
    const resp = await API.get(process.env.REACT_APP_BASE_URL + "v1/users/"+userId);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getDeskeraUserInfo() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/deskera/user");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function connectFoodicsStore(path, body) {
  try {
    const resp = await API.post(path, body);
  } catch (err) {
    throw err;
  }
}

// --------------------------------------------------------
//
//            /*Foodics API calls*/
//
// --------------------------------------------------------

export async function getFoodicsOrders(limit = 10, page = 0, sortBy = [{ id: "", desc: "" }]) {
  try {
    const id = sortBy.length > 0 ? sortBy[0].id : "";
    const desc = sortBy.length > 0 ? sortBy[0].desc : "";
    const sortDir = desc ? "DESC" : "ASC";
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/orders?limit=" + limit + "&page=" + page +
      "&sortBy=" +
      id +
      "&sortDir=" +
      sortDir
);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsPurchases() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/purchases");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsAccounts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/connections");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsProducts() {
  try {
    const resp = await API.get(process.env.REACT_APP_PRODUCT_URL + "?limit=200000");
    return resp.data.content;
  } catch (err) {
    throw err;
  }
}
export async function getFoodicsUOM() {
  try {
    const resp = await API.get(process.env.REACT_APP_PRODUCT_URL + "/uom");
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getFoodicsUOMConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/uom-config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function setFoodicsUOMConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/foodics/uom-config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getFoodicsInventory() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/inventory-items");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsInventoryTransactions(limit = 10, page = 0, sortBy = [{ id: "", desc: "" }]) {
  try {
    const id = sortBy.length > 0 ? sortBy[0].id : "";
    const desc = sortBy.length > 0 ? sortBy[0].desc : "";
    const sortDir = desc ? "DESC" : "ASC";
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/inventory-transactions?limit="+limit+"&page="+page +
    "&sortBy=" +
    id +
    "&sortDir=" +
    sortDir);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setFoodicsInventory() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/foodics/inventory-items");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setFoodicsInventoryTransactions(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/foodics/inventory-transactions", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function updateFoodicsInventoryTransactions(id, body) {
  try {
    const resp = await API.put(process.env.REACT_APP_SERVER_URL + "/foodics/inventory-transactions/"+id, body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsPurchaseOrders(limit = 10, page = 0, sortBy = [{ id: "", desc: "" }]) {
  try {
    const id = sortBy.length > 0 ? sortBy[0].id : "";
    const desc = sortBy.length > 0 ? sortBy[0].desc : "";
    const sortDir = desc ? "DESC" : "ASC";
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/purchase-orders?limit="+limit+"&page="+page +
    "&sortBy=" +
    id +
    "&sortDir=" +
    sortDir);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/account-config");
    if (resp.data) {
      return resp.data[0];
    } else return {};
  } catch (err) {
    throw err;
  }
}

export async function setFoodicsConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/foodics/account-config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsProdConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/product-config");
    if (resp.data) {
      return resp.data[0]
    } else return {};
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsOrdersBranches() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/branches");
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getFoodicsPurchaseOrdersWarehouses() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/warehouses");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setFoodicsProdConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/foodics/product-config",
      newConfig
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setFoodicsPaymentAccountConfig(newConfig) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/foodics/payment-config",
      newConfig
    );
    console.log(newConfig);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsPaymentAccountConfig() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/payment-config");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function setFoodicsConnection(connectionInfo) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/foodics/apikey",
      connectionInfo
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function enableFoodicsSync() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/foodics/sync/enable");
  } catch (err) {
    throw err;
  }
}

export async function disableFoodicsSync() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/foodics/sync/disable");
  } catch (err) {
    throw err;
  }
}

export async function setDisconnectedFoodicsAccount(shopUrl) {
  try {
    const resp = await API.post(
      process.env.REACT_APP_SERVER_URL + "/cart/foodics/disconnect?accountId=" + shopUrl
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateFoodicsData(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/cart/foodics/migrate", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function migrateFoodicsPurchaseOrdersData(body) {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/foodics/purchase-orders", body);
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsJobStatus() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/job-status");
    return resp.data;
  } catch (err) {
    throw err;
  }
}
export async function getFoodicsContacts() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/contacts");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function getFoodicsSuppliers() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/foodics/suppliers");
    return resp.data;
  } catch (err) {
    throw err;
  }
}


export async function getFoodicsRedirectUrl() {
  try {
    const resp = await API.get(process.env.REACT_APP_SERVER_URL + "/cart/foodics/redirectUrl");
    return resp.data;
  } catch (err) {
    throw err;
  }
}

export async function activateFoodicsSetup() {
  try {
    const resp = await API.post(process.env.REACT_APP_SERVER_URL + "/foodics/setup");
    return resp.data;
  } catch (err) {
    throw err;
  } 
}

