import { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Select from "react-select";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useAsyncDebounce,
  usePagination,
} from "react-table";
import ArrowRight from "src/assets/ArrowRight";
import ForwardRight from "src/assets/ForwardRight";
import SearchLogo from "src/assets/Search";
import UpArrowIcon from "src/assets/UpArrow";
import Alert from "src/components/Alert";
import Table from "src/components/Table";
import {
  loadPurchaseOrders,
  loadConfig,
  loadAccounts,
  loadjob,
  loadJob,
} from "src/redux/actions/foodics";
import customSelectTheme from "src/utils/selectTheme";
import SyncStatus from "src/components/SyncStatus";
import { migrateFoodicsPurchaseOrdersData } from "src/api";
import { addToast } from "src/redux/actions/toasts";
import FoodicsTable from "src/components/FoodicsTable";

function Purchases() {
  const purchaseOrders = useSelector((state) => state.foodics.purchaseOrders);
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const job = useSelector((state) => state.foodics.job);
  const [pageSize, setPageSize] = useState(10);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageCount, setPageCount] = useState(0);
  const [originalData, setOriginalData] = useState();
  const [loading, setLoading] = useState(false);
  const [sortBy, setSortBy] = useState();

  const columns = useMemo(
    () => [
      {
        Header: "Purchase Transaction Reference",
        accessor: "cartPurchaseOrderId",
      },
      {
        Header: "Supplier",
        accessor: "supplierName",
        disableSortBy: true
      },
      {
        Header: "Branch",
        accessor: "branchName",
        disableSortBy: true
      },
      
      {
        Header: "Business Date",
        accessor: "businessDate",
      },
      {
        Header: "Sync Status",
        accessor: "syncStatus",
        Cell: ({ cell: { row } }) => {
          return (
            <SyncStatus
              status={row.original.syncStatus}
              reason={row.original.reason}
              id={row.original.cartPurchaseOrderId}
            />
          );
        },
        disableGlobalFilter: true,
      },
      {
        Header: "Deskera Order Code",
        accessor: "deskeraDocumentCode",
      },
    ],
    []
  );
  
  function initialiseData() {
    setOriginalData(purchaseOrders.data);
    if (!purchaseOrders.data || !purchaseOrders.data.content) {
      return
    }
    let dat = [];
    for (let i = 0; i < purchaseOrders.data.content.length; i++) {
      let purchaseOrder = purchaseOrders.data.content[i];
      let bDate = purchaseOrder.businessDate.substring( 0 , 10 );;
      let data1 = {
        branchName : purchaseOrder.branch.name,
        syncStatus : purchaseOrder.syncStatus,
        reason : purchaseOrder.reason,
        supplierName: purchaseOrder.supplier.name,
        businessDate: bDate,
        deskeraDocumentCode: purchaseOrder.deskeraDocumentCode,
        cartPurchaseOrderId: purchaseOrder.cartPurchaseOrderId,
      };
      dat.push(data1);
    }
    setData(dat);
  }

  useEffect(() => {
    dispatch(loadConfig());
    dispatch(loadAccounts);
  }, []);

  useEffect(() => {
    initialiseData();
  }, [purchaseOrders]);


  useEffect(() => {
    setLoading(false);
    if(originalData && originalData.totalPages) {
      setPageCount(originalData.totalPages);
    }
  }, [originalData]);

  const config = useSelector((state) => state.foodics.config);

  async function handleSyncDataSubmit() {
    try {
      const resp = await migrateFoodicsPurchaseOrdersData();
    } catch (err) {
      dispatch(
        addToast({
          type: "danger",
          title: "Failure",
          message: err.message,
        })
      );
    }
  }

  const refreshData = ({ pageSize, pageIndex, sortBy}) => {
    setPageSize(pageSize);
    setPageIndex(pageIndex);
    setLoading(true);
    setSortBy(sortBy);
    dispatch(loadPurchaseOrders(pageSize, pageIndex, sortBy));
  }; 

  return (
    <div className="p-4">
      <h3 className="mb-3">Purchases</h3>
      {(!config.data || !config.data.syncEnabled) && (
        <Alert type="warning">
          Your synced Purchases will appear here. Please complete{" "}
          <Link to="/app/foodics" className="text-alert-warning">
            <u>Setup</u>
          </Link>{"  "}
          before continuing.
        </Alert>
      )}
      <FoodicsTable
        data={data ? data : []}
        columns={columns}
        migrate={migrateFoodicsPurchaseOrdersData}
        cart="foodics"
        pages = "purchases"
        pageCount={pageCount} refresh={refreshData}
        loading={loading}
      />
    </div>
  );
}

export default Purchases;
